html,
body {
  padding: 0;
  margin: 0;
}

.otpWrap input.reactOtpInput {
  width: 3.5rem !important;
  height: 3.8rem;
  border-radius: 5px;
  text-align: center;
  border: 1px solid #d5d5d5;
  margin: 0 33px 0 0;
  font-size: 1.25rem;
}

body {
  @apply text-xs;
}

.idvc-steps, .d-none, .idvc__footer {
  display: none !important;
}

.idvc {
  display: contents !important;
}

.ReactModalPortal {
  .idvc .uploader-wrap {
    min-height: 240px;
  }

  .idvc .idvc-license-error {
    margin-top: 0;
  }

  .vc-loading-mask {
    border-radius: 8px;
  }


  .el-loading-spinner {
    margin-top: -46px;
    @apply justify-center items-center;
  }

  .idvc .idvc-type-hint + .uploader-wrap--enabled {
    margin-top: 0;
  }
}

main {
  .rccs {
    width: 100%;
  }

  .rccs__card {
    height: 220px;
    width: 100%;

    .rccs__card--front, .rccs__card--back {
      background: #cacaca;
    }
  }

  .rccs__chip {
    top: 17%;
    left: 8%;
    border: 3px #fff solid;
    width: 57px;
    height: 45px;
    border-radius: 10px;
  }

  .rccs__issuer {
    top: 14%;
  }

  .rccs__card--front, .rccs__card--back {
    border-radius: 10px;
  }

  .rccs__number, .rccs__name, .rccs__expiry, .rccs__cvc {
    opacity: 1;
    color: #fff;
    @apply font-medium tracking-wider;
  }

  .rccs__number, .rccs__name {
    left: 8%;
    font-size: 1.25rem;
  }

  .rccs__expiry__value {
    font-size: 1.25rem;
  }

  .rccs__expiry__valid {
    font-size: 10px;
    @apply uppercase font-medium;
  }
}

input:focus,
textarea:focus {
  outline: none;
}

.idvc .main-container {
  padding-top: 45% !important;
}

.brandLogo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.125rem;

  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    object-fit: contain;
  }
}

@media screen and (max-width: 800px) {
  .idvc .main-container {
    padding-top: 60% !important;
  }

}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type=number], input[type=text], input[type=url] {
  font-size: 1rem;
  font-weight: 400;
}

input[type=number] {
  -moz-appearance: textfield;
}

.error {
  color: #ff4949;

  input {
    border-color: #ff4949;
  }
}

.text-black {
  color: #000;
}

.fs-30 {
  font-size: 1.875rem !important;
}

.fs-20 {
  font-size: 1.25rem !important;
}

.border-danger {
  border-color: red !important;
}

.successTick {
  width: 1.6875rem;
  height: 1.6875rem;
  @apply flex items-center justify-center rounded-full;
  &.passed {
    @apply bg-green-500;
  }
}

.btn {
  line-height: 48px;
  @apply px-9 rounded-3xl text-white font-medium uppercase tracking-wider;
  &.w-full + &.w-full {
    margin-block-start: 1.25rem;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none
  }
}

.lg-btn {
  @apply w-full px-4 h-16 rounded text-sm font-semibold uppercase tracking-wider bg-green-500 text-white;
  &.type1 {
    background: #929292;
    color: #fff;
    font-size: 1rem;
    text-transform: none;
    letter-spacing: normal;
    font-weight: normal;
    margin-top: 1.125rem;
  }

  &:disabled {
    background: #d5d5d5;
    font-size: 20px;
    letter-spacing: 0.15rem;
    @apply rounded w-full uppercase text-white;
    &:hover {
      cursor: not-allowed;
    }
  }

  &.bold-style {

    font-size: 0.875rem;
    letter-spacing: 0.15rem;
  }
}

.smBtn {
  @apply rounded px-4 py-2;
  font-size: 14px;

  &:disabled {
    background: #cacaca;
    color: #fff;
    cursor: not-allowed;
  }
}

.text-field {
  @apply rounded bg-gray-100 border border-solid h-12 md:h-16 px-4 placeholder:font-normal;
}

.otpWrap {
  @apply flex;
  .text-field {
    margin-right: 0.375rem;
    max-width: 3.75rem;
    max-height: 4.375rem;
    text-align: center;
    font-size: 1.25rem;

    &:last-child {
      margin-right: 0;
    }

    &.border-danger {
      color: #ed3b3b;
    }
  }
}

.form-message {
  @apply text-xs font-medium;
  margin: 0.3125rem 0 0.625rem 0;

  span {
    color: #054278;
  }

  &.default {
    color: #a3a3a3;
  }

  &.error {
    color: #ff4949;
  }
}

.check-box {
  label {
    color: #7a7a7a;
    @apply flex leading-5 pl-8 relative text-sm cursor-pointer;
    input {
      & + span {
        border-color: #cfcfcf;
        @apply w-6 h-6 rounded border-2 border-solid block absolute left-0 top-0;
      }

      &:checked + span {
        @apply border-gray-500;
        &::before {
          @apply absolute font-bold text-green-300 font-sans text-lg;
          content: '';
          left: 3px;
          width: 14px;
          height: 19px;
          background-size: 16px;
          background: url('Public/images/check.png') no-repeat;
        }
      }
    }
  }
}


.form-input {
  @apply rounded bg-slate-100 border border-slate-400 px-3 h-20 mb-3 text-sm text-center focus:bg-white;
}

.form-control {
  @apply relative;
  input {
    @apply h-16 md:h-16 w-full px-4 outline-0;
  }

  select {
    font-size: 1rem;
    font-weight: 500;
    --tw-bg-opacity: 1;
    color: RGB(0 0 0/var(--tw-bg-opacity));
    text-transform: capitalize;

    &:focus-visible {
      outline: 0;

      option[value=""] {
        color: #999999;
      }
    }
  }

  label {
    @apply font-medium mb-3 block;
  }

  .suffix {
    @apply absolute text-base  right-7 uppercase tracking-widest font-medium;
    transform: translateY(-43%);
    color: rgba(0, 0, 0, 0.3);
    top: 46%
  }

  .text-field {
    @apply pl-5 pr-16;
  }
}

main {
  position: relative;
  //&::after {
  //  background: RGB(102, 151, 82);
  //  background: linear-gradient(220deg, rgb(61, 108, 150) 0%, rgb(11, 92, 163) 100%);
  //  height: 208px;
  //  @apply absolute left-0 top-0 w-full z-0;
  //  content: "";
  //}

  &.gradient-bg {
    background: RGB(255, 255, 255);
    background: linear-gradient(133deg, rgba(255, 255, 255, 0) 0%, rgba(237, 242, 254, 1) 100%);
  }
}

.swichMode {
  button {
    width: 70px;
    height: 40px;
    background: #001d38;
    @apply mx-5 rounded-full;
  }
}

.slideCircle {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  position: relative;
  background: #fff;

  &::after {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background: #499045;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    content: "";
  }

  &.activeState {
    transform: translateX(30px);
  }
}


.footer-container {
  @apply w-full flex justify-center;
  max-width: 510px;
  min-height: 40px;
  background: #e9ebf1;

  ul {
    @apply flex items-center;
    li {
      color: rgba(0, 0, 0, 0.5);
      padding: 0 16px;
      position: relative;

      &::after {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.3);
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-25%);
        content: '';
      }

      &:first-child {
        padding-left: 0;

        &::after {
          display: none;
        }
      }

      &:last-child {
        padding-right: 0;
      }

      a {
        &:hover {
          color: #054278;
        }
      }
    }
  }
}

.rdt_TableHead, .rdt_TableCol {
  background-color: #e7eaf8;
  padding: 0 !important;
  z-index: 1 !important;
  justify-content: flex-start;
}

.rdt_TableCell {
  padding: 3px 0 !important;
  --tw-text-opacity: 1;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  //text-transform: capitalize;
  font-size: 13px;
  text-align: center;
  color: #9fa0a3;
}

.rdt_TableRow {
  border-top: solid 5px #f9faff;
  border-bottom: solid 5px #f9faff;
}

.table_ui_style .rdt_TableRow {
  &:hover {
    @apply bg-green-100
  }
}


.el-loading-spinner .circular {
  margin: 0 auto;
}


.react-tabs__tab {
  @apply p-2 text-gray-600 cursor-pointer my-2;
  &:hover {
    @apply bg-gray-100;
  }

  &.react-tabs__tab--selected {
    @apply bg-gray-200 text-gray-700;
  }
}


.react-daterange-picker__clear-button {
  @apply hidden;
}

.react-daterange-picker__calendar-button svg {
  stroke-width: 0.1rem;
  width: 15px;
  stroke: #7a7a7a;
}

.react-daterange-picker__button:enabled:hover .react-daterange-picker__button__icon {
  stroke: #054278;
}

.react-calendar .react-calendar__tile {
  margin: 1px;
  max-width: calc(14.2857% - 2px);
}

.react-calendar .react-calendar__year-view__months__month, .react-calendar .react-calendar__tile.react-calendar__decade-view__years__year, .react-calendar .react-calendar__tile.react-calendar__century-view__decades__decade {
  max-width: calc(33.3333% - 2px);
}

.react-daterange-picker__wrapper {
  background: transparent !important;
  height: 44px;
  width: 13rem;
  border-color: RGB(229 231 235 / 1) !important;
  @apply border rounded-r text-sm text-gray-600 font-normal overflow-hidden;
}

.daterange_picker.bg-white .react-daterange-picker__wrapper {
  border: solid 1px #fff !important;
}

.react-daterange-picker__wrapper .react-daterange-picker__inputGroup__leadingZero {
  font-size: 13px;
  padding: 1px 0 0;
}

.react-daterange-picker__calendar-button {

  @apply absolute top-0 left-0 right-0 bottom-0 w-full h-full text-green-500 hover:text-green-500;
}

.react-daterange-picker__inputGroup {
  text-align: center;
  align-items: center;
}

.react-daterange-picker__inputGroup input {
  font-size: 13px !important;
  font-weight: 400 !important;
}

.react-daterange-picker__clear-button {
  order: 5;
  width: 25px;
  position: absolute;
}

.react-daterange-picker__clear-button svg {
  width: 100%;
}

.react-daterange-picker__wrapper div:nth-child(1) {
  order: 2;
  margin-left: 22px;
}

.react-daterange-picker__wrapper span:nth-child(2) {
  order: 3;
  margin-left: -10px;
}

.react-daterange-picker__wrapper div:nth-child(3) {
  order: 4;
}

.react-daterange-picker__wrapper div:nth-child(4) {
  order: 5;
}

.react-daterange-picker__wrapper div:nth-child(5) {
  order: 1;
  margin-right: 20px;
}

.react-daterange-picker__inputGroup__input {
  padding-left: 5px
}

.react-daterange-picker__wrapper .react-daterange-picker__inputGroup__input {
  min-width: auto !important;
  //width: auto !important;
  padding-left: 1px;
}

.react-daterange-picker__wrapper .react-daterange-picker__inputGroup__leadingZero {
  margin-right: 6px;
  margin-left: 2px
}

.tableBtn {
  background: #0542780d;
  color: #054278;
}

.tableBtn:hover {
  background: #ffffff;
  color: #000000;
}

.tableBtnHover:hover {
  background: #44cf761a;
  color: #000000;
}

.react-daterange-picker__inputGroup {
  display: none !important;
}

.react-daterange-picker__calendar {
  @apply shadow-lg rounded-xl;
}

.react-daterange-picker__calendar .react-calendar {
  @apply border-0 p-2 rounded-xl;
  border: 1px solid #f1f1f1;
}

.react-daterange-picker__calendar abbr[title] {
  text-decoration: none;
}

.react-calendar__tile {
  @apply text-gray-500 rounded;
}

.react-calendar__tile--now {
  @apply bg-indigo-200 text-indigo-800 rounded;
}

.react-daterange-picker__inputGroup__input:invalid {
  @apply bg-transparent;
}

.react-calendar__navigation__arrow {
  @apply rounded min-w-fit h-8 w-5;
}

.react-calendar__navigation__label {
  @apply rounded min-w-fit h-8 leading-8;
}

.react-calendar__tile--active {
  @apply bg-indigo-200 text-indigo-800;
}

.react-calendar__tile--rangeStart.react-calendar__tile--range {
  @apply bg-indigo-500 text-white rounded-none rounded-l;
}

.react-calendar__navigation {
  height: auto;
  margin-bottom: 5px;
  column-gap: 0.25rem;
  justify-content: space-between;
}

.react-calendar__tile--range {
  @apply rounded-none;
}

.react-calendar__tile--rangeEnd.react-calendar__tile--range {
  @apply bg-indigo-500 text-white rounded-none rounded-r;
}

.react-daterange-picker__calendar {
  @apply w-64;
}

select.react-daterange-picker__inputGroup__month {
  -moz-appearance: none;
  -webkit-appearance: none;
}

select.react-daterange-picker__inputGroup__month::-ms-expand {
  display: none;
}

.react-daterange-picker .react-daterange-picker__inputGroup {
  @apply flex;
}

.react-daterange-picker__inputGroup__input.react-daterange-picker__inputGroup__year {
  margin-left: 5px;
}

.idvc .uploader__text {
  color: #fff !important;
}

.idvc .uploader__icon-camera {
  background-color: #fff !important;
  padding: 5px;
  color: #ccc;
  margin-bottom: 30px;
}

.idvc .uploader__icon-camera svg {
  stroke: #333;
  width: 21px;
  height: 21px;
  stroke-width: 27px;
}

.idvc .message-box--primary {
  color: #054278;
  border: 1px solid #002b50;
}

.idvc main::after {
  height: 100%;
  border-radius: 5px;
  display: none;
}

.idvc main {
  min-height: 240px;
}


.multiselect-container {
  .searchWrapper {
    @apply w-full border border-gray-200 focus:border-green-400 text-sm px-5 py-2 leading-8 bg-white rounded-lg transition-all;
    .searchBox {
      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        font-size: 14px;
        font-weight: 400;
        color: #758599;
      }

      &::-moz-placeholder { /* Firefox 19+ */
        font-size: 14px;
        font-weight: 400;
        color: #758599;
      }

      &:-ms-input-placeholder { /* IE 10+ */
        font-size: 14px;
        font-weight: 400;
        color: #758599;
      }

      &:-moz-placeholder { /* Firefox 18- */
        font-size: 14px;
        font-weight: 400;
        color: #758599;
      }
    }
  }
}

.react-tel-input .flag-dropdown.open .selected-flag, .react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus {
  --tw-bg-opacity: 1;
}

.react-tel-input .country-list .search {
  display: flex;
  align-items: center;
}

.react-tel-input .country-list .search-box {
  height: auto !important;
}

.input-tel-container .form-control {
  border: 0 !important;
  width: 100% !important;
  padding-left: 1rem;
  padding-right: 1rem;
  outline-width: 0;
  --tw-bg-opacity: 1;
}

.react-tel-input .flag-dropdown {
  border: 0 !important;
  padding-left: 27px;
  background-color: transparent !important;
}

.phone-validator .PhoneInput {
  border: none !important;
}

.glc1 {
  fill: #0f9ad6;
}

.glc2 {
  fill: #173D5D;
}

@media screen and (max-width: 800px) {
  .otpWrap input.reactOtpInput {
    width: 2.8rem !important;
    height: 3rem;
    margin: 0 12px 0 0;
  }
}

@media screen and (max-width: 1200px) {
  input[type="color"],
  input[type="date"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  select:focus,
  textarea {
    font-size: 15px;
  }
}

@media screen and (max-width: 600px) {
  .swichMode button {
    width: 48px;
    height: 20px;
  }
  .slideCircle {
    width: 20px;
    height: 20px;
  }
  .slideCircle::after {
    width: 9px;
    height: 9px;
  }
  .lg-btn {
    @apply w-full px-4 md:h-14 rounded text-sm md:text-sm font-medium uppercase tracking-wider ;
    &.bold-style {
      letter-spacing: 0.10rem;
    }
  }

}

@media screen and (max-width: 400px) {
  .otpWrap input.reactOtpInput {
    width: 2.5rem !important;
    height: 3rem;
  }
}

.custom-search-box {
  .reset {
    position: absolute;
    top: 12px;
    right: 50px;
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ddd;
    border-radius: 100%;
    border: none;
    padding: 0;
    cursor: pointer;
    transition: .1s;
  }
}

.animate-bounce-up {
  animation: bounceOne 1s linear;
}

@keyframes bounceOne {
  0%, to {
    transform: translateY(-5px);
  }
  20% {
    transform: translateY(-50px);
  }

  55% {
    transform: translateY(0);
  }

}

.roll-select {
  margin-bottom: 10px;
  margin-right: 8px;
  white-space: nowrap;

  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }

  label {
    padding: 7px 12px;
    background-color: #fff;
    border: 1px #054278 solid;
    cursor: pointer;
    border-radius: 1.25rem;
  }

  input:checked + label {
    background-color: #054278;
    color: #fff;
  }
}

// /* width */
// // ::-webkit-scrollbar {
// //   width: 10px;
// // }
::-webkit-scrollbar {
  height: 0.375rem;
  width: 0.375rem;
  border-radius: 0.625rem;
  opacity: 0;

  &-track {
    background: rgba(28, 142, 68, 0);
    border-radius: 0.625rem;
  }

  &-thumb {
    background: rgba(28, 142, 68, 0);
    border-radius: 0.625rem;
  }

  &-corner {
    background: transparent;
  }
}

.scroll-ho, .pagenation, .rdt_Table, .dash-table > div {

  &:hover {
    &::-webkit-scrollbar {
      &-track {
        background: rgba(black, 0.1);
      }

      &-thumb {
        background: rgba(black, 0.3);
      }
    }
  }
}

.pagenation .rdt_TableCol_Sortable > span:last-child {
  margin-left: 5px;
}


.dash-table .rdt_TableHead {
  z-index: 4;
}

.react-daterange-picker__wrapper {
  height: 40px;
}

.signingcontainer {
  display: block;
}

.basis-content {
  flex-basis: content;
}


@media screen and (min-width: 1162px) {
  .margin_0 {
    margin-top: 0;
  }
}

@media screen and (min-width: 975px) {
  .margin-top_0 {
    margin-top: 0;
  }
}

@media screen and (min-width: 1024px) {
  .margin-top_0 {
    margin-top: 0.5rem;
  }
}

@media screen and (min-width: 1162px) {
  .margin-top_0 {
    margin-top: 0;
  }
}

.form-control.h-full.flag-input .react-tel-input .form-control {
  height: 60px;
}

.form-control.h-full.flag-input input {
  font-weight: 600;
}

@media screen and (max-width: 767px) {
  .form-control.h-full.flag-input .react-tel-input .form-control {
    height: 46px;
  }
  .rdt_Pagination .sc-ezWOiH {
    justify-content: center;
  }
  .dash-table > div {
    max-height: 60vh;
  }
  .withdrawl-download {
    position: absolute;
    width: auto;
    right: 13px;
  }


}

.dash-table .sc-dmRaPn, .sc-dmRaPn {
  max-height: 100% !important;
}

// .sc-dmRaPn.iXRfeU.pagenation, .drx{max-height: 50vh;}
.rdt_Pagination .sc-llJcti {
  display: block;
}

.iXRfeU {
  flex-grow: 1;
}

input[type="date"] {
  display: block !important;
  -webkit-appearance: textfield !important;
  -moz-appearance: textfield !important;
  min-height: 1.2em !important;
  min-width: 95%;
}

input[type="date"]::before {
  content: attr(data-date);
}

input[type="date"] {
  position: relative;
  color: #ece8e8;
  min-height: 1.2em !important;
}

input[type="date"]:before {
  position: absolute;
  top: 10px;
  left: 5px;
  content: attr(data-date);
  display: inline-block;
  color: black;
}

input[type="date"]::-webkit-datetime-edit, input::-webkit-inner-spin-button, input::-webkit-clear-button {
  display: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 10px;
  right: 5px;
  color: black;
  opacity: 1;
}

.ihtyEN {
  min-width: 0 !important;
}

.mainWrapper {
  min-height: 100vh;
  height: 100%;

  & > div:first-child {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}

.custom {
  &From {
    div {
      &:nth-last-of-type(3), &:nth-last-of-type(2) {
        position: relative;
      }
    }
  }

  &Wrapper {
    input[type='checkbox'] {
      width: 3.75rem;
      height: 1.875rem;
      position: absolute;
      border: none;
      margin: 0;
      top: 0;
      right: 0;
      opacity: 0;
      z-index: 2;
      cursor: pointer;

      & + .customToggle {
        width: 3.75rem;
        height: 1.875rem;
        border: 0.0625rem #999 solid;
        border-radius: 1.25rem;
        top: 0;
        right: 0;
        position: absolute;
        background-color: #efefef;
        cursor: pointer;

        &:before {
          width: 1.625rem;
          height: 1.625rem;
          content: '';
          background-color: #aaa;
          display: block;
          border-radius: 50%;
          left: 0.125rem;
          top: 0.0625rem;
          position: absolute;
          transition: all .3s;
        }
      }

      &:checked {
        & + .customToggle {
          border-color: #054278;
          background-color: #e8f4ff;

          &:before {
            left: auto;
            right: 0.125rem;
            background-color: #054278;
          }
        }
      }
    }
  }
}

.react-date-picker__wrapper {
  border: none !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-popper {
  z-index: 10 !important;
  margin-inline-start: -1.25rem;
  position: fixed !important;
}

.react-datepicker__input-container {
  display: flex !important;
  align-items: center;
}

.react-datepicker__view-calendar-icon input {
  cursor: pointer;
  height: 2.5rem;
  line-height: 1.5;
  padding: 0.5rem 0.9375rem 0.5rem 1.875rem !important;
  width: 100%;
  border-radius: 0.3125rem;
  font-size: 0.9375rem;
  padding: 0.875rem 1.375rem;
  border: rgba(0, 0, 0, 0.3) solid 0.0625rem;
  background-color: lighten($color: #0068ff, $amount: 49%);

  &:focus {
    border-color: rgba(#0350BF, 1);
  }

  &:disabled {
    background-color: darken($color: #fff, $amount: 10%);
    color: rgba(0, 0, 0, 0.60);
  }

  &::placeholder {
    font-size: 1rem;
    font-weight: 400;
  }
}

.idvc .main-container {
  padding-top: 45% !important;
}

@media screen and (max-width: 800px) {
  .idvc .main-container {
    padding-top: 60% !important;
  }
  .idvc .vc-dialog-bottom {
    bottom: 50% !important;
  }
}

.update-notification {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.card-steps-center, .idvc-version-plank-container, #message-box-container, #back-button, .test-uploader__icons-container{
  display: none!important;
}

.idvc .main-screen-container{
  display: flex;
  justify-content: center;
  width: 100%;
}

.initial-loader-background{
  min-height: 450px;
  bottom: 0;
  margin: auto 0;
}
.steps-list-screen{
  margin: auto;
}
