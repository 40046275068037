@import './theme.scss';

* {
  outline: none;
  text-decoration: none;
  box-sizing: border-box;
  scroll-behavior: auto;
  scroll-behavior: smooth;
  scroll-behavior: inherit;
  scroll-behavior: initial;
  scroll-behavior: revert;
  //scroll-behavior: revert-layer;
  scroll-behavior: unset;

}

*:hover {
  text-decoration: none;
}

html {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  font-size: 16px;
  line-height: 1.5;
  font-family: $fontFace;
  background-color: $white;
}

input:-internal-autofill-selected {
  background-color: transparent !important;
}

a {
  text-decoration: none;
}

svg {
  width: 100%;
  height: 100%;
}

img {
  width: 100%;
  height: 100%;
  object-fit: contain
}

::-webkit-scrollbar {
  height: 0.375rem;
  width: 0.375rem;
  border-radius: 0.625rem;
  opacity: 0;

  &-track {
    background: rgba($black, 0);
    border-radius: 0.625rem;
  }

  &-thumb {
    background: rgba($black, 0.2);
    border-radius: 0.625rem;
  }

  &-corner {
    background: transparent;
  }

}

:hover {
  &::-webkit-scrollbar {
    &-track {
      background: rgba($black, 0.1);
    }

    &-thumb {
      background: rgba($black, 0.3);
    }
  }
}

.inputWsubmit {
  background: #f8f9fa;
  border-radius: 0.3125rem;
  font-size: 0.9375rem;
  padding: 0.75rem 1.375rem;
  border: rgba(0, 0, 0, 0.3) solid 0.0625rem;
  max-width: 100%;
  margin-block-end: 0.625rem;
  position: relative;

  input {
    @apply mr-3 tracking-widest text-sm font-medium bg-white bg-opacity-0 w-full;
    &::placeholder {
      @apply tracking-normal text-base font-normal;
      color: #626262;
    }
  }
}

.clear, [class^='clear-'], [class*=' clear-'] {
  clear: both;
  font-size: 0;
  line-height: 0;
}

.clear {
  &10 {
    height: 0.625rem;
  }

  &20 {
    height: 1.25rem;
  }

  &30 {
    height: 1.875rem;
  }

  &40 {
    height: 1.875rem;
  }

  &60 {
    height: 3.75rem;
  }
}

.width {
  &Full {
    width: 100%;
  }
}

.lc1 {
  fill: $secondary;
}

.lc2 {
  fill: #231f20;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;

  &.or {
    margin-block: 0.875rem;
    line-height: 1;
    text-align: center;
  }

  &.titel {
    font-size: 3.125rem;
  }
}

h1 {
  font-size: 1.5625rem;
}

h2 {
  font-size: 1.25rem;
  margin-block-end: 1.25rem;
  font-weight: 500;
}

h3 {
  font-size: 1.125rem;
  margin-block-end: 1rem;
  font-weight: 400;
}

h4 {
  font-size: 1rem;
  margin-block-end: 0.9375rem;
  font-weight: 400;
}

h5 {
  font-size: 0.875rem;
}

h6 {
  font-size: 0.75rem;
}

p {
  font-size: 0.875rem;

  & + p {
    margin-block-start: 0.75rem;
  }

  &.body {
    color: $black;
    font-size: 1rem;
  }

  &.body1 {
    color: rgba($black, 0.80);
  }

  &.body2 {
    font-size: 0.85rem;
    color: rgba($black, 0.40);
  }
}

.btn {
  padding: 1.25rem 1.875rem;
  font-size: 1.125rem;
  text-transform: capitalize;
  font-weight: 400;
  line-height: 1.5;
  border-radius: 0.625rem;
  border: 0.0625rem solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &Sm {
    padding: 0.5rem .875rem;
    font-size: 0.875rem;
    line-height: 1;
    font-weight: 400;
  }

  &Lg {
    padding: 1.5rem 2.5rem;
    font-size: 2.5rem;
    line-height: 1.5;
    box-shadow: 0 0 1.875rem 0 rgba($secondary, 0.64);
  }

  &Full {
    width: 100%;
  }

  &.contained {
    background-color: rgba($black, 0.05);
    border-color: rgba($black, 0.1);

    &[color='primary'] {
      background-color: $primary;
      color: $white;
      border-color: $white;
    }

    &[color='secondary'] {
      background-color: $secondary;
      color: $white;
      border-color: $white;
    }

    &[color='accent'] {
      background-color: $accent;
      color: $white;
      border-color: $white;
    }

    &[color='warm'] {
      background-color: $warm;
      color: $white;
      border-color: $warm;
    }

    .brand {
      height: 3.125rem;

      .lc1, .lc2 {
        fill: $white;
      }
    }
  }

  &Box {
    padding: 0.5rem;
    width: 3.75rem;
    height: 3.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.outline {
    border-color: rgba($black, 0.1);

    &[color='primary'] {
      border-color: $primary;
      color: $primary;
    }

    &[color='secondary'] {
      border-color: $secondary;
      color: $secondary;
    }

    &[color='accent'] {
      border-color: $accent;
      color: $accent;
    }

    &[color='warm'] {
      border-color: $warm;
      color: $warm;
    }
  }

  &[color='primary'] {
    color: $primary;
  }

  &[color='secondary'] {
    color: $secondary;
  }

  &[color='accent'] {
    color: $accent;
  }

  &[color='warm'] {
    color: $warm;
  }

  &Graphics {
    position: absolute;
    bottom: 0;
    right: -1.25rem;
    width: 17.5rem;
    height: 6.25rem;
    opacity: 0.35;
    pointer-events: none;

    &Top {
      width: 5rem;
      height: 5rem;
      bottom: auto;
      top: -0.75rem;
      right: auto;
      left: 0;
    }
  }

  .suffix {
    width: 1.25rem;
    height: 1.25rem;
    margin-inline-end: 1rem;
  }

  &Icon {
    width: 2.5rem;
    height: 2.5rem;
    padding: 0.3125rem;
  }

  &.widthFull {
    & + &.widthFull {
      margin-block-start: 0.625rem;
    }
  }

  &.disabled, &:disabled {
    cursor: no-drop;
    opacity: 0.5;
    color: lighten($color: $black, $amount: 50%) !important;
    background-color: rgba($black, 0.25) !important;
  }
}

button.disabled, button:disabled {
  cursor: no-drop;
  opacity: 0.5;
  color: lighten($color: $black, $amount: 50%) !important;
}

label {
  color: $black;
  font-size: 0.9375rem;
  font-weight: 400;
  //margin-block-end: 0.75rem;
  display: block;
  text-transform: capitalize;
}

.formFiled {
  margin-block-end: 0.625rem;
  position: relative;
  width: 100%;

  label {
    font-size: 1rem;
    font-weight: 400;
    color: rgba($color: $black, $alpha: 0.5);
    position: absolute;
    left: 1rem;
    top: 1.125rem;
    margin-block-end: 0;
    transition: all .3s linear;
    padding-inline: 0.5rem;
    pointer-events: none;
  }

  // label.floting{font-size: 1rem;font-weight: 600;}
  .suffix {
    position: absolute;
    right: 1rem;
    font-weight: 600;
    color: rgba($black, 0.5);
    top: 1.125rem;

    & + input {
      padding-inline-end: 5rem;
    }
  }

  button.suffix {
    font-size: 0.875rem;
    bottom: 1rem;

    &.verify {
      color: $success;
    }
  }

  & > input, & > select {
    width: 100%;
    border-radius: 0.625rem;
    font-size: 0.9375rem;
    line-height: 2;
    padding: 0.875rem 1.375rem;
    border: rgba($black, 0.2) solid 0.0625rem;
    background-color: lighten($color: $accent, $amount: 49%);

    &:focus {
      border-color: $primary;

      & + label {
        transform: scale(0.95);
        transform-origin: left center;
        top: -.75rem;
        background-color: $white;
      }
    }

    &:not(:placeholder-shown) {
      & + label {
        transform: scale(0.95);
        transform-origin: left center;
        top: -.75rem;
        background-color: $white;
      }
    }

    &:disabled {
      background-color: #e6e6e675;
      color: rgba(0, 0, 0, 0.6);
    }

    &::placeholder {
      font-weight: 400;
    }
  }

  .react-datepicker__input-container {
    input {
      height: 2.5rem;
      line-height: 1.5;
    }
  }

  &Sm {
    label {
      font-size: 0.875rem;
      font-weight: 400;
      margin-block-end: 0.25rem;
      opacity: 0.75;
    }

    .suffix {
      right: 0.5rem;
      font-weight: 500;
      bottom: 0.625rem;
      font-size: 0.875rem;

      & + input {
        padding-inline-end: 3.125rem;
      }
    }

    button.suffix {
      bottom: 0.75rem;
    }

    & > input, & > select {
      padding: 0.5rem 0.9375rem;
      font-weight: 400;
    }
  }
}

.inputWsubmit {
  background: #f8f9fa;
  border-radius: 0.3125rem;
  font-size: 0.9375rem;
  padding: 0.75rem 1.375rem;
  border: rgba(0, 0, 0, 0.3) solid 0.0625rem;
  max-width: 100%;
  margin-block-end: 0.625rem;
  position: relative;

  input {
    @apply mr-3 tracking-widest text-sm font-medium bg-white bg-opacity-0 w-full;
    &::placeholder {
      @apply tracking-normal text-base font-normal;
      color: #626262;
    }

    button.suffix {
      bottom: 0.75rem;
    }

    & > input, & > select {
      padding: 0.5rem 0.9375rem;
      font-weight: 400;
    }
  }
}

.text {
  &Primary {
    color: $primary !important;
  }

  &Gray {
    color: lighten($color: $black, $amount: 40%) !important;
  }

  &Black {
    color: $black !important;
  }

  &White {
    color: $white !important;
  }

  &Success {
    color: $success !important;
  }

  &Danger {
    color: $warm !important;
  }

  &Center {
    text-align: center;
  }

  &Right {
    text-align: right;
  }

  &Gradient {
    background: linear-gradient(271deg, $accent 15.15%, $sky 135.36%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

}

.fw {
  &400 {
    font-weight: 400 !important;
  }

  &600 {
    font-weight: 600 !important;
  }

  &700 {
    font-weight: 700 !important;
  }
}

.fs {
  &14 {
    font-size: 0.875rem !important;
  }

  &16 {
    font-size: 1rem !important;
  }

  &17 {
    font-size: 1.0625rem !important;
  }
}

.py {
  &10 {
    padding-block: 0.625rem;
  }

  &20 {
    padding-block: 1.25rem;
  }

  &22 {
    padding-block: 1.375rem;
  }

  &25 {
    padding-block: 1.625rem;
  }

  &30 {
    padding-block: 1.875rem;
  }
}

.main {
  flex: 1;
  //height: 100%;
  &Container {
    width: 100%;
    display: flex;

    &:before {
      content: '';
      background-color: rgba($secondary, 0.05);
      clip-path: polygon(100% 0, 100% 100%, 0 100%);
      position: fixed;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }
}

.adjustBefore {
  &:before {
    content: '';
    background-color: rgba($black, 0.5);
    clip-path: none;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 99;
  }

  .containtWrapper {
    max-width: 40.5rem;
  }
}

.modal {
  position: relative;
  background-color: $white;
  border-radius: 1.25rem;
  box-shadow: 0 1.5625rem 3.125rem -0.75rem rgba($black, 0.25);
  z-index: 999;
  margin-inline: auto;

  &Wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    z-index: 999;
    outline: none;
    width: 100%;
    height: 100%;
    transition: all 0.3s;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
  }

  &Box {
    max-width: 50rem !important;
    margin: auto
  }

  &Sm {
    max-width: 31.25rem !important;
    width: 95vw;
  }

  &Md {
    max-width: 40rem !important;
    width: 40rem;
  }

  &Lg {
    max-width: 70rem !important;
    width: 95vw;
  }

  &Scanner {
    max-width: 50rem !important;
    width: 95vw;
  }

  &About {
    max-width: 40rem !important;
    width: 95vw;
  }

  &Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1.875rem;
    border-block-end: rgba($black, 0.1) solid 0.0625rem;

    h3, h4 {
      margin-block-end: 0
    }
  }

  &Body {
    padding: .50rem 1.875rem;
    max-height: calc(100vh - 10rem);
    overflow: auto;

    &.gap {
      &0 {
        padding: 0.625rem 0;
      }

      &10 {
        padding: 0.625rem;
      }

      &20 {
        padding: 1.25rem;
      }
    }
  }

  &Action {
    padding: 0 1.875rem 1.625rem;
    position: relative;
    z-index: 2;
    align-items: center;
    border-radius: 0 0 1.25rem 1.25rem;
  }

  &Fixed {
    padding: 0 1.25rem 1.25rem;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: $white;
    position: absolute;
    z-index: 3
  }

  &Floting {
    width: 100%;
    position: sticky;
    z-index: 1;
    background-color: $white;
    bottom: 0;
    align-self: flex-end;
  }

  &Bg {
    z-index: 20;
    backdrop-filter: blur(0.25rem);
    background-color: rgba($color: #000000, $alpha: 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.containtWrapper {
  margin: 0 auto;
  max-width: 100%;
  padding-inline: 1.875rem;
  padding-block: 1.25rem 0;
  min-height: 85vh;
  display: flex;
  width: 100%;
  position: relative;
  justify-content: center
}

.maintWrapper {
  margin: 0 auto;
  width: 100%;
  padding-inline-start: 21.875rem;
  min-height: 100%;
  display: flex;
  position: relative;
}

.nav {
  &Wrapper {
    position: relative;
    display: flex;
    align-items: center;
    margin-inline-start: auto;
  }

  &Bg {
    transition: all .3s;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba($black, 00);
    pointer-events: none;
    z-index: 8;
    backdrop-filter: blur(0.25rem);
    opacity: 0;

    &Active {
      pointer-events: all;
      opacity: 1;
      background-color: rgba($black, 0.50);
    }
  }

  &List {
    display: flex;
    align-items: center;
    z-index: 2;
  }

  &Link {
    padding: 0.5rem 0.75rem;
    border-radius: 0.3125rem;
    color: $primary;
    font-size: 0.875rem;
    font-weight: 600;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: rgba(lighten($color: $primary, $amount: 49%), 0.5);
    }

    & + .navLink {
      margin-inline-start: .75rem;
    }

    &.dark {
      padding-inline: 1.25rem;
      background: $primary;
      color: $white;

      &:hover {
        background-color: darken($color: $primary, $amount: 20%);
      }
    }
  }

  &Mobile {
    display: none;
    align-items: center;
    margin-inline: auto 0.75rem;

    .navLink {
      font-size: 0.75rem;
      width: auto;
      background-color: rgba($primary, 1);
      color: $white;
      padding: 0.5rem 0.75rem;

      & + .navLink {
        margin-inline-start: 0.5rem;
        margin-block-start: 0;
      }
    }
  }

  &Icon {
    width: 1.875rem;
    height: 1.875rem;
    display: none;
    background-color: $white;
    position: relative;
    z-index: 2;
    padding: 0.25rem;

    svg {
      fill: $primary;
    }
  }
}

// .brand{width: 4.375rem;}
header {
  padding: 0.5rem 1.25rem;
  background-color: $white;;
  position: relative;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  .brand {
    width: 10rem;
    height: 3.125rem;
  }

  .MuiAvatar-root {
    background-color: $primary;
    color: $white;
    font-size: 1.25rem;
    font-weight: 600;
  }

}

.footer {
  background-color: $white;
  text-align: center;
  color: lighten($color: $black, $amount: 60%);
  font-size: 0.875rem;
  position: sticky;
  bottom: 0;
  padding: 1.25rem 1.625rem;
  margin-block-start: auto;

  a {
    color: lighten($color: $black, $amount: 28.5%);
    margin-inline: 0.25rem;
  }
}

.bgGraphics {
  width: 11.25rem;
  height: 11.25rem;
  position: fixed;

  &.tl {
    left: 0;
    top: 3.75rem;
    transform: rotate(180deg);
  }

  &.br {
    right: 0;
    bottom: 0;
  }
}

.login {
  &Wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;
    width: 100%;
    overflow: hidden;
    margin: auto;
    padding: 1.5rem 2.5rem;
  }

  &Card {
    max-width: 45rem;
    width: 100%;
    padding-inline: min(10.833vw, 6.25rem);
    padding-block: 3.125rem;
    margin: -0.375rem auto 0;
    background-color: $white;
    box-shadow: 0 0 1.5625rem 0 rgba($black, 0.05);
    border-radius: 1.25rem;
    border: rgba($secondary, 0.05) solid 0.125rem;

    &.padSmall {
      padding: 2.5rem;
    }

    &.widthSmall {
      max-width: 30rem;
    }
  }

  &Logo {
    width: 6.75rem;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 2.5rem;
  }

  &Body {
    display: block;
  }
}

.text-base {
  padding-block: 1.25rem;
}

.info {
  &List {
    &.gapSm {
      li {
        & + li {
          margin-block-start: 0.625rem;
        }
      }
    }

    li {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      font-size: .95rem;

      & + li {
        margin-block-start: 0.3375rem;
      }
    }
  }

  &Label {
    display: flex;
    align-items: center;
    color: lighten($color: $black, $amount: 30%);
  }

  &Value {
    justify-content: flex-end;
    text-align: right;
    display: flex;
    align-items: center;
    color: lighten($color: $black, $amount: 30%);
  }

  &Icon {
    display: flex;
    align-items: center;
    width: 1.25em;
    height: 1.25em;
    margin-inline-end: 1.25rem;
  }
}

.bodyContent {
  padding: 1.25rem;
  padding-block-end: 0;
  min-height: 50vh
}

.transfarLogo {
  width: 90%;
  max-width: 15rem;
  margin: 0 auto 1.25rem;
}

.accountPart {
  position: fixed;
  top: 5.25rem;
  left: calc(((100% - 62.5rem) / 2) + 1.875rem);
  height: calc(100% - 8.75rem);
  width: 20rem;
  border-radius: 0.625rem;
  padding: 0.75rem;
  background-color: $primary;
  box-shadow: 0 0 1.5625rem 0 rgba($black, 0.03);
  flex-direction: column;
  display: flex;
  gap: 1.25rem;

  & ~ .bodyPart {
    margin-inline-start: 21.25rem;
  }
}

.accountSticky {
  position: sticky;
  top: 0.75rem;
  flex-direction: column;
  display: flex;
  gap: 1.25rem;
}

.price {
  &Box {
    display: flex;
    flex-direction: column;
    gap: 0.9375rem;
  }

  &Card {
    text-align: center;
    padding: 0.875rem 1.25rem;
    background-color: $white;
    border-radius: 0.3125rem;
    box-shadow: 0 0 10px 0 rgba(0, 104, 255, 0.10);

    h2 {
      margin-block-end: 0;
      line-height: 1;
    }

    p {
      margin-block-end: 0.5rem;
    }
  }

  &Btn {
    padding-inline: 0.75rem;
  }
}

.loadBtn {
  margin-block-start: auto;

  button {
    color: $white;
  }
}

.card {
  &Wrapper {
    color: $white;

    h4 {
      margin-block-end: 0.5rem;
    }
  }

  &List {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 0.9375rem;

    li {
      position: relative;
      display: flex;
      align-items: center;
    }
  }

  &Box {
    display: flex;
    align-items: center;
    flex-grow: 1;
    flex-basis: 0;
    gap: 0.75rem;
  }

  &Logo {
    background-color: rgba($white, 0.9);
    border-radius: 0.375rem;
    border: rgba($secondary, 0.8) solid 0.0625rem;
    width: 3.75rem;
    padding: 0.3125rem;

    .cardNo {
      color: $primary;
      font-weight: 600;
      text-align: right;
      font-size: 0.75rem;
      line-height: 1;
    }
  }

  &Image {
    width: 100%;
    height: 1.5rem;

    img {
      object-position: left center;
    }
  }

  &Details {
    color: rgba($white, 0.5);
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25;
  }

  &Btn {
    border-inline-start: rgba($white, 0.3) solid 0.0625rem;
    padding-inline-start: 0.5rem;
  }
}

.body {
  &Part {
    width: 45rem;
    margin: 0 auto;
    background-color: $white;
    box-shadow: 0 0 1.5625rem rgba($black, 0.05);
    border-radius: 0.625rem;
    padding: 2.25rem 3.25rem;
    z-index: 9;
  }

  &Contant {
    max-width: 30rem;
    margin: 0 auto;
  }

  &Form {
    margin: 0 auto;
  }

  &Btn {
    padding: 1rem 0;
    position: sticky;
    background-color: $white;
    z-index: 3;
    top: 0;
  }

  &Info {
    margin-inline: -1.25rem;
    padding-inline: 1.25rem;
    width: calc(100% + 2.5rem);

    h3 {
      padding-block: 0.8975rem;
      background-color: $white;
      position: sticky;
      top: 10.75rem;
    }

    &Sm {
      h3 {
        top: 14rem;
      }
    }
  }
}

.transactions {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    display: flex;
    border: rgba($secondary, 0.3) solid 0.0625rem;
    border-radius: 0.625rem;
    padding: 0.75rem 1rem;
  }

  &Details {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;

    h4 {
      font-weight: 400;
      color: rgba($black, 0.8);
      margin-block-end: 0.25rem;
    }

    h5 {
      font-weight: 400;
      color: rgba($black, 0.5);
    }
  }

  &Price {
    h4 {
      color: rgba($black, 0.8);
      margin-block-end: 0.25rem;
    }
  }
}

.tip {
  display: flex;
  padding: 0;
  margin: 0;
  justify-content: center;

  li {
    width: calc((100% - 1.25rem) / 3);
    position: relative;
  }

  &Box {
    background-color: rgba($accent, 0.05);
    border: rgba($accent, 0.2) solid 0.0625rem;
    padding: 0.875rem;
    display: flex;
    flex-direction: column;
    border-radius: 0.625rem;
    text-align: center;
    height: 100%;
    justify-content: center;

    h3 {
      margin-block-end: 0;
    }

    &[color='warm'] {
      background-color: rgba($warm, 0.05);
      border-color: rgba($warm, 0.25);
      color: $warm;
    }

  }
}

.sig {
  //&box {border-radius: 0.3125rem;border: 0.0625rem solid rgba($black, 0.3);background: lighten($color: $secondary, $amount: 49%);width: 100%;max-width: 37.5rem;height: 8.75rem;display: flex;align-items: center;justify-content: center;position: relative;}
  &box {
    border-radius: 0.625rem;
    border: 0.0625rem solid rgba($black, 0.1);
    background-color: lighten($color: $accent, $amount: 49%);
    width: 100%;
    max-width: 100%;
    height: 11rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    legend {
      font-size: 1rem;
      font-weight: 400;
      color: rgba($color: $black, $alpha: 0.5);
      margin-inline: 1rem;
      padding-inline: 0.5rem;
      pointer-events: none;
    }

    canvas {
      width: 100% !important;
      height: 100% !important;
    }
  }

  &Pad {
    width: 100%;
    height: 100%;
  }
}

.clearsig {
  position: absolute;
  right: 0;
  bottom: 0;
}

.receipt {
  .brand {
    max-width: 12.5rem;
    margin-inline: auto;
    margin-block-end: 2.5rem;
  }
}

.table {
  &Layout {
    position: relative;
    min-height: calc(100vh - 7.5rem);
    padding: 1.25rem;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &Wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-grow: 1;
    background-color: $white;
    box-shadow: 0 0 1.5625rem 0 rgba($black, 0.05);
    border-radius: 0.625rem;
    padding: 1.25rem 1.25rem 0.25rem;
  }

  &Box {
    flex-grow: 1;
    padding-block-start: 1.25rem;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;

    .iXRfeU {
      flex-grow: 1;
    }
  }

  &Filter {
    position: relative;
    z-index: 2;

    &Wrapper {
      display: flex;
      align-items: center;
      gap: 0.625rem;
    }

    &Date {
      display: flex;
      align-items: center;

      & > div {
        flex-grow: 1;
        flex-basis: 0;
        max-width: 100%;
        display: flex;
      }
    }

    &Status {
      display: flex;
      align-items: center;

      select {
        width: 100%;
      }
    }

    &Nav {
      display: flex;
      align-items: center;
      flex-grow: 1;
      flex-basis: 0;
      max-width: 100%;
    }

    &Search {
      display: flex;
      min-width: 15rem;
      margin-inline-start: auto;
    }
  }
}

.idvc-steps, .d-none, .idvc__footer {
  display: none !important;
}

.idvc {
  display: contents !important;
}

.idvc .uploader-wrap {
  min-height: 240px;
}

.idvc .idvc-license-error {
  margin-top: 0;
}

.idvc .idvc-type-hint + .uploader-wrap--enabled {
  margin-top: 0;
}

.idvc .uploader__text {
  color: #fff !important;
}

.idvc .uploader__icon-camera {
  background-color: #fff !important;
  padding: 5px;
  color: #ccc;
  margin-bottom: 30px;
}

.idvc .uploader__icon-camera svg {
  stroke: #333;
  width: 21px;
  height: 21px;
  stroke-width: 27px;
}

.idvc .message-box--primary {
  color: #054278;
  border: 1px solid #002b50;
}

.idvc main::after {
  height: 100%;
  border-radius: 5px;
  display: none;
}

.idvc main {
  min-height: 240px;
}

.pinWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100% - 12.5rem);
}

.home {
  &Wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 7.5rem);
  }

  &Logo {
    width: 25%;
  }
}

@import './responsive.module.scss';

.text_transform_none {
  text-transform: none !important;
}
