@import './theme.scss';

@media screen and (max-width: 1200px) {
  .table {
    &Filter {
      &Wrapper {
        flex-wrap: wrap;
      }

      &Status {
        display: flex;
        align-items: center;
      }

      &Date {
        display: flex;
        align-items: center;
      }

      &Nav {
        order: 2;
        width: 100%;
        flex-basis: unset;
      }

      &Search {
        display: flex;
        min-width: 15rem;
      }
    }
  }
  .modal {
    &Scanner {
      max-width: 90vw !important;
      width: 95vw;
    }
  }
}

@media screen and (max-width: 1020px) {
  .btn {
    &.widthFull {
      & + &.widthFull {
        margin-block-start: 0.9375rem;
      }
    }
  }
  .accountPart {
    width: 100%;
    position: static;
    gap: 0.625rem;
    height: auto;

    & + .bodyPart {
      margin-block-start: 1.25rem;
      margin-inline-start: 0;
    }
  }
  .accountSticky {
    gap: 0.625rem;
  }
  .nav {
    &Wrapper {
      margin-inline-start: 0;
    }

    &List {
      transition: all 0.5s;
      position: fixed;
      width: 100vw;
      max-width: 15rem;
      right: -100%;
      top: 4rem;
      padding: 1.25rem;
      box-shadow: 0 1.25rem 1.5625rem rgba($black, 0.05);
      background-color: $white;
      flex-direction: column;
      height: calc(100% - 4rem);
      overflow: hidden;

      &Active {
        overflow: visible;
        right: 0;
      }
    }

    &Link {
      width: 100%;
      text-align-last: left;
      justify-content: flex-start;

      & + .navLink {
        margin-inline-start: 0;
        margin-block-start: .25rem;
      }
    }

    &Mobile {
      display: flex;
    }

    &Icon {
      display: block;
    }
  }
  .price {
    &Box {
      flex-direction: row;
      align-items: center;
      width: 100%;
    }

    &Card {
      width: 100%;
      flex-grow: 1;
      flex-basis: 0;
      display: flex;
      justify-content: space-between;
      text-align: left;
      align-items: center;

      h2 {
        margin-block-end: 0.625rem;
      }

      p {
        margin-bottom: 0;
      }

      div:nth-child(2) {
        justify-content: center;
        flex-direction: column;
        display: flex;
        align-items: center;
      }
    }

    &Btn {
      margin: 0 auto;
      min-width: 50%;
      display: flex;
      flex-direction: column;
      gap: 0.625rem;
    }
  }
  .card {
    &List {
      flex-direction: row;
      overflow: auto;

      li {
        border: rgba($white, 0.3) solid 0.0625rem;
        padding: 0.25rem;
        background-color: $accent;
        border-radius: 0.5rem;
      }
    }

    &Box {
      display: flex;
      align-items: center;
      flex-grow: 1;
      flex-basis: 0;
      gap: 0.75rem;
    }

    &Logo {
      width: 3.75rem;
      border-radius: 0.25rem;

      .cardNo {
        color: $primary;
        font-weight: 600;
        text-align: right;
        font-size: 0.75rem;
        line-height: 1;
      }
    }

    &Details {
      padding-inline-end: 0.5rem;
      text-align: right;
      white-space: nowrap;
    }

    &Btn {
      border-inline-start: rgba($white, 0.3) solid 0.0625rem;
      padding-inline: 0.5rem;
    }
  }

  .body {
    //&Part{width: 100%;}
    &Contant {
      max-width: 100%;
    }

    &Form {
      max-width: 40rem;
      margin: 0 auto;
    }

    &Btn {
      padding: 0.5rem 1.25rem;
      display: flex;
      align-items: center;
      gap: 0.625rem;
    }

    &Info {
      margin: 0 auto;
      width: 100%;

      h3 {
        top: 10rem;
      }

      &Sm {
        h3 {
          top: 5.25rem;
        }
      }
    }
  }


  .sig {
    &box {
      max-width: 100%;
    }
  }
}

@media screen and (max-width: 600px) {
  h1, h2, h3, h4, h5, h6 {
    &.or {
      margin-block: 0.625rem;
    }

    &.titel {
      font-size: 1.625rem;
    }
  }
  h1 {
    font-size: 1.375rem;
  }
  h2 {
    font-size: 1.125rem;
    margin-block-end: 0.75rem;
  }
  h3 {
    font-size: 1rem;
    margin-block-end: 0.75rem;
  }
  h4 {
    font-size: 0.9rem;
    margin-block-end: 0.75rem;
  }
  h5 {
    font-size: 0.75rem;
  }
  h6 {
    font-size: 0.625rem;
  }

  .btn {
    padding: 1rem 1.5rem;
    font-size: 0.9375rem;
    font-weight: 500;
    line-height: 1.25;
    border-radius: 0.5rem;

    &Sm {
      padding: 1rem 1.875rem;
      line-height: 1.5;
    }

    &Lg {
      padding: 1.5rem 2.5rem;
      font-size: 2.5rem;
      line-height: 1.5;
      box-shadow: 0 0 1.875rem 0 rgba($secondary, 0.64);
    }

    &Icon {
      width: 1.875rem;
      height: 1.875rem;
      padding: 0.25rem;
    }

    .suffix {
      width: 1.25rem;
      height: 1.25rem;
      margin-inline-end: 1rem;
    }
  }
  label {
    font-size: 0.8125rem;
  }
  .containtWrapper {
    padding-inline: 0.625rem;
    padding-block-end: 0;
  }
  .main {
    &Container {
      &:before {
        display: none;
      }
    }
  }
  .modal {
    &Header {
      padding: 0.9375rem 1.25rem;
    }

    &Body {
      padding: 1rem;
    }

    &Action {
      padding: 0 1.25rem 1.25rem;
    }
  }

  .login {
    &Wrapper {
      padding: 0;
    }

    &Card {
      padding-inline: min(10.833vw, 1.25rem);
      padding-block: 2.5vh 5vh;

      &.padSmall {
        padding-inline: min(10.833vw, 1.25rem);
      }
    }

    &Logo {
      width: 6.25rem;
      margin: 0 auto 2.5rem;
    }
  }

  .price {
    &Box {
      flex-direction: column;
      background-color: $white;
      box-shadow: 0 0 10px 0 rgba(0, 104, 255, 0.10);
      border-radius: 0.3125rem;
      gap: 0;
    }

    &Card {
      box-shadow: none;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $white;
      color: $black;
      text-align: left;
      padding: 0.625rem;

      h2 {
        color: $accent;
        margin-block-end: 0;
      }

      h4 {
        margin-block-end: 0.25rem;
      }

      p {
        color: $black;
        margin-block-end: 0;
      }
    }

    &Btn {
      display: flex;
      margin: 0;
      flex-direction: row-reverse;
      width: 100%;

      .btn {
        padding: .625rem;
        max-width: 100%;
        font-size: 0.75rem;
        flex-grow: 1;
        border-radius: 0.3125rem;
        width: auto;

        &.widthFull + .widthFull {
          margin-block-start: 0 !important;
          margin-inline-end: 0.9375rem;
        }
      }
    }
  }

  .body {
    &Part {
      max-height: unset;
      padding: 0.625rem;
      overflow: visible;
    }

    &Form {
      max-width: 40rem;
      margin: 0 auto;
    }

    &Btn {
      padding: 0;
      padding-block-start: 0.5rem;
      flex-direction: column;
      gap: 0;
      top: 0;
    }

    &Info {
      max-height: unset;
      width: 100%;
      margin: 0;
      padding-inline: 0;

      h3 {
        margin-block-end: 0;
        top: 8.75rem;
      }

      &Sm {
        h3 {
          top: 9.25rem;
        }
      }
    }
  }
  .table {
    &Layout {
      padding: 0.25rem;
    }

    &Wrapper {
      padding: 0.625rem 0.5rem 0.25rem;
    }
  }
  .dataTable {
    position: relative;
    // div{min-width: 100%;width: auto; max-width: unset;}
    & > div {
      & > div {
        display: table;

        & > div {
          &:first-child {
            display: table-header-group;
          }

          &:nth-child(2) {
            display: table-row-group;
          }

          & > div {
            display: table-row;
            border-top: #e7eaf8 solid 0.0625rem;

            & > div {
              display: table-cell;
              padding: 0.5rem !important;
            }
          }
        }
      }
    }
  }

}

@media screen and (max-width: 600px) {
  .table {
    &Filter {
      &Status, &Date {
        width: 100%;
      }

      &Nav {
        order: 1;
        width: 100%;
        flex-basis: unset;
      }

      &Search, &Role {
        width: 100%;

        select {
          width: 100%;
          font-size: 0.875rem;
        }
      }
    }
  }
  .home {
    &Logo {
      width: 35vw;
    }
  }
}
