@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

.adminMain::after {
  display: none !important;
}

.nav_link {
  @apply flex transition-all items-center px-3 mx-1 py-2 font-semibold hover:bg-green-50 space-x-4 rounded-md hover:text-green-500;
}

.bg_style {
  @apply relative;
}

.bg_style::before {
  @apply absolute top-0 right-0 left-0 bottom-0 opacity-60;
  content: "";
  background-repeat: no-repeat;
  background-position: 100% 0;
  background-size: 70%;
}

.daterange_picker {
  @apply w-full;
  &.bg-white {
    .react-daterange-picker__wrapper {
      border: 0 !important;
    }
  }
}

.react-daterange-picker__wrapper {
  border: 0 !important;
}

.table_ui_style {
&>div{max-height: unset;}
  thead {

    th {
      @apply px-4 py-4 text-gray-500 border-0 text-xs font-medium m-0 uppercase;
      background-color: #e7eaf8;

      &:last-child {
        @apply rounded-r-lg;
      }

      &:first-child {
        @apply text-left rounded-l-lg;
      }
    }
  }

  tbody tr {
    border-top: solid 5px #f9faff;
    border-bottom: solid 5px #f9faff;

    &:hover td {
      @apply bg-green-100;
    }

    &:hover td .badge {
      @apply bg-white;
      &.badge_primary {
        @apply bg-white;
      }

      &.badge_denger {
        @apply bg-white;
      }

      &.badge_wirning {
        @apply bg-white;
      }
    }

    td {
      @apply transition-all px-4 py-3 text-gray-500;
      font-size: 13px;
      background-color: #fff;
      text-align: center;
      color: #9fa0a3;

      &.text_dark {
        @apply text-gray-700 font-semibold;
      }

      &:last-child {
        @apply rounded-r-lg;
      }

      &:first-child {
        @apply text-left rounded-l-lg;
      }

      .badge {
        @apply font-semibold px-3 py-1 inline-block rounded-3xl transition-all;

        &.badge_primary {
          @apply text-green-500 bg-green-500 bg-opacity-10;

        }

        &.badge_denger {
          @apply text-red-500 bg-red-500 bg-opacity-10;
        }

        &.badge_wirning {
          @apply text-yellow-500 bg-yellow-500 bg-opacity-10;
        }
      }

      button.table_btn {
        @apply text-gray-500 transition-all;
        &:hover {
          @apply text-green-500;
        }
      }
    }
  }
}

.timeline_step {
  @apply relative z-10;
  &::after {
    content: "";
    @apply border-l border-gray-300 top-4 left-3 bottom-5 w-1 absolute z-0;
  }

  li {
    @apply relative z-10;
  }
}

.form_control_sm {
  border: solid 1px #ccc;
  font-size: 13px;
  color: #555454;
  width: 100%;
  padding: 5px 7px;
  border-radius: 5px;
  outline: none;
  height: 35px;
}

.form_control_xs {
  border: solid 1px #e7e7e7;
  color: #555454;
  width: 100%;
  padding: 5px 7px;
  border-radius: 5px;
  outline: none;
  font-weight: 400 !important;
  height: 35px;
  font-size: 0.75rem !important;
  line-height: 1rem !important;
}

.search_input {
  font-size: 13px !important;
  color: #555454;
  font-weight: 400 !important;
}

.form_control_md {
  @apply border text-gray-600 rounded px-3 py-2 border-gray-300 leading-6 placeholder:text-sm placeholder:font-normal placeholder:text-gray-300 w-full;
  font-weight: 400 !important;
  font-size: 14px !important;
}

select.form_control_md {
  @apply border text-gray-600 rounded px-3 py-2 border-gray-300 leading-6 h-10 placeholder:text-sm placeholder:font-normal placeholder:text-gray-300 w-full;
  font-weight: 400 !important;
  font-size: 14px !important;
}

.pagination_nav {
  display: flex;
  align-items: center;

  a {
    min-width: 30px;
    height: 30px;
    border-radius: 4px;
    text-align: center;
    font-size: 13px;
    color: #868585;
    transition: all 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 2px;
    padding: 5px;
  }
}

.pagination_nav a:hover {
  @apply bg-green-500 text-white;
}

.pagination_nav a.active {
  @apply bg-green-500 text-white;
}

.form_control {
  @apply w-full border border-gray-200 focus:border-green-400 text-sm px-5 py-3 bg-white rounded-lg h-14 placeholder-gray-600 transition-all;
  font-weight: 400 !important;

  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-size: 14px;
    font-weight: 400;
  }

  &::-moz-placeholder { /* Firefox 19+ */
    font-size: 14px;
    font-weight: 400;
  }

  &:-ms-input-placeholder { /* IE 10+ */
    font-size: 14px;
    font-weight: 400;
  }

  &:-moz-placeholder { /* Firefox 18- */
    font-size: 14px;
    font-weight: 400;
  }

}

.abc {
  border-color: #e4174e !important;
}

textarea.form_control {
  height: 100px;
}


.check_box input[type="checkbox"] {
  @apply hidden;
  & + label {
    @apply inline-block cursor-pointer text-sm text-gray-600 leading-5;
    &::before {
      content: "\2713";
      @apply border leading-4 border-gray-400 rounded font-bold text-center inline-block w-5 h-5 pb-2 mr-3 text-transparent transition-all;
    }

    &:active::before {
      transform: scale(0);
    }
  }

  &:checked + label::before {
    @apply bg-green-500 border-green-700 text-white;
  }

  &:disabled + label:before {
    transform: scale(1);
    border-color: #aaa;
  }

  &:checked:disabled + label:before {
    transform: scale(1);
    background-color: #bfb;
    border-color: #bfb;
  }
}


.switch_box {
  @apply relative inline-block;
  .input_switch {
    @apply absolute opacity-0;
    & + label {
      @apply block relative cursor-pointer outline-none w-10 md:w-14 h-5 md:h-7 px-2;
      &.switch_view_status {
        @apply ml-16 mr-16;
      }

      &::before {
        @apply block absolute top-0 left-0 bottom-0 transition-all right-0 bg-gray-200 rounded-2xl shadow-inner;
        content: "";
      }

      &::after {
        @apply block absolute top-0 left-0 bottom-0 w-3 h-3 md:w-5 md:h-5 bg-gray-50 rounded-full mx-1 my-auto transition-all shadow-md;
        content: "";
      }

      & + .switch_status_box {
        span {
          @apply absolute text-xs top-0 mt-1 block ml-20 align-middle font-semibold text-gray-500;
          &.switch_deactive {
            @apply text-red-500 left-0 ml-0;
          }

          &.switch_active {
            @apply text-gray-500 left-36 ml-0;
          }
        }
      }
    }

    &:checked + label {
      &:before {
        @apply bg-green-500 shadow-inner ;
      }

      &:after {
        @apply ml-8 transition-all left-0;
      }

      & + .switch_status_box {
        span {

          &.switch_deactive {
            @apply text-gray-500;
          }

          &.switch_active {
            @apply text-green-500;
          }
        }
      }
    }
  }
}

.switch_box input:disabled + label:before {
  @apply bg-gray-50 cursor-not-allowed;
}

.switch_box input:disabled + label span {
  @apply bg-gray-200 cursor-not-allowed;
}

.switch_box input:disabled + label:after {
  @apply bg-gray-200 cursor-not-allowed;
}


.switch_boxUI .md_toggleUI {
  @apply absolute opacity-0;
}

.switch_boxUI .md_toggleUI + label {
  @apply block relative cursor-pointer outline-none;
}

.switch_boxUI input.input_switchUI + label {
  @apply w-16 h-8 px-2;
}

.switch_boxUI input.input_switchUI + label:before, input.input_switchUI + label:after {
  @apply block absolute top-0 left-0 bottom-0;
  content: "";
}

.switch_boxUI input.input_switchUI + label:before {
  @apply transition-all right-0 bg-gray-200 rounded-2xl shadow-inner shadow-gray-400;
}

.switch_boxUI input.input_switchUI + label:after {
  @apply w-6 h-6 bg-gray-50 rounded-full mx-1 my-auto transition-all shadow-md;
}

.switch_boxUI input.input_switchUI:checked + label:before {
  @apply bg-green-500 shadow-inner shadow-green-800;
}

.switch_boxUI input.input_switchUI:checked + label:after {
  @apply ml-9 transition-all;
}

.switch_boxUI input.input_switchUI:checked + label span {
  @apply text-gray-600;
}

.switch_boxUI label span {
  @apply absolute top-2 block text-sm ml-20 align-middle font-medium text-gray-700;
}

.coustomerModal {
  z-index: 9999;
}

.coustomerModalBg {
  z-index: 998;background-color: rgba($color: #000000, $alpha: 0.5);
}

.form_card {
  background-size: 100%;
}

.page_card_box {
  @apply bg-white flex rounded-lg p-7 py-10 shadow-sm shadow-slate-100 hover:shadow-2xl hover:shadow-slate-200 transition-all;
  min-height: 217px;

  .page_card_icon {
    border: solid 2px #79d0ae;
    @apply w-20 h-20 p-5 rounded-full;
  }
}

.footer_menu {
  @apply flex;
  li {
    @apply relative pl-7 pr-7 text-sm leading-9 text-gray-500;
    &::before {
      content: "";
      @apply absolute w-2 h-2 rounded-full bg-gray-400 left-0 top-4;
    }

    &:first-child {
      &::before {
        @apply hidden;
      }
    }
  }
}


@media screen and (max-width: 767px) {


  .switch_box .input_switch:checked + label:after {
    @apply -left-2;
  }
}
  @media screen and (max-width: 800px) {

    .footer_menu {
    flex-wrap: wrap;
    justify-content: center;

    li {
      @apply pl-5 pr-5 text-xs leading-6;
      &::before {
        @apply w-1 h-1 bg-gray-400 top-3;
      }

      &:first-child {
        width: 100%;
        text-align: center;
      }

      &:nth-child(2) {
        &::before {
          display: none;
        }
      }

    }
  }
}


@media screen and (max-width: 600px) {
  .footer_menu {
    flex-wrap: wrap;
    justify-content: center;

    li {
      @apply pl-5 pr-5 text-xs leading-6;
      &::before {
        @apply w-1 h-1 bg-gray-400 top-3;
      }

      &:first-child {
        width: 100%;
        text-align: center;
      }

      &:nth-child(2) {
        &::before {
          display: none;
        }
      }

    }
  }
  .form_control {
    @apply px-4 py-1 h-9 md:h-12;
    font-weight: 400 !important;

  }
}



@media screen and (max-width: 420px) {
  .form_control {
    @apply px-4 py-1 h-9 md:h-12;
    font-weight: 400 !important;

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      font-size: 13px;
      font-weight: 400;
    }

    &::-moz-placeholder { /* Firefox 19+ */
      font-size: 13px;
      font-weight: 400;
    }

    &:-ms-input-placeholder { /* IE 10+ */
      font-size: 13px;
      font-weight: 400;
    }

    &:-moz-placeholder { /* Firefox 18- */
      font-size: 13px;
      font-weight: 400;
    }

  }
}
