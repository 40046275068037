//@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Chivo:wght@400;500;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

$primary:#011E46;
$secondary:#011B44;
$accent:#0068FF;
$sky: #00B7DF;
$black:#000;
$white:#fff;
$warm:#f44336;
$success:#0abe3d;
$fontFace:'Poppins', sans-serif;
$fontInter: 'Inter', sans-serif;
$fontHeading: 'Chivo', sans-serif;
$grid-breakpoints: (
  // Extra small screen / phone
  xs: 0,
  // Small screen / phone
  sm: 576px,
  // Medium screen / tablet
  md: 768px,
  // Large screen / desktop
  lg: 1000px,
  // Extra large screen / wide desktop
  xl: 1200px
);
/*
.nbcuprocessing{
  $secondary:#756EFB;
}*/
