@tailwind base;
@tailwind components;
@tailwind utilities;

.scrollbar-thin {
    overflow: scroll;
}

#screen-scanning {
    position: relative;
}

#camera-feedback {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    width: 100%;
    height: 100%;
}

#camera-guides {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
    font-weight: bold;
}

.card-container {
    overflow: hidden;
    height: auto;
    max-height: 300px;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

.h-100 {
    max-height: 2000px;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}
.driver-dropdown .transition{
    opacity: 0;
}
